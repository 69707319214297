<span *ngIf="entry.author?.[0] else noAuthorBadgeTmp" class="badge badge-secondary">CDA</span>
<ng-template #noAuthorBadgeTmp>
    <span class="badge badge-warning text-white" *ngIf="entry.id < 0; else notNewBadgeTmp">New</span>
    <ng-template #notNewBadgeTmp>
        <ng-container *ngIf="patient">
            <app-bst-badge *ngIf="patient.isBst"
                           [ngbTooltip]="modifiedAtText">
            </app-bst-badge>
            <span *ngIf="!patient.isBst || entry.copied_from"
                  [ngbTooltip]="!patient.isBst && modifiedAtText"
                  class="badge badge-brand-3 bg-brand-3 text-white">
                Arsana
            </span>
        </ng-container>
    </ng-template>
    <span *ngIf="entry.patient_document" class="badge badge-secondary">CDA</span>
</ng-template>
