import {Component, Input} from '@angular/core';
import {isId} from '../../../../utils/type.utils';
import {RelatedPatient} from '../../../../models/patient';
import {GetterCache} from '../../../../utils/accessor.utils';
import {SimpleDatePipe} from '../../../../@theme/pipes/simple-date.pipe';

@Component({
    selector: 'app-entry-source-badge-component',
    templateUrl: './entry-source-badge-component.component.html',
    styles: [`:host {display: contents}`],
})
export class EntrySourceBadgeComponentComponent {
    @Input() entry;

    @GetterCache()
    get patient() {
        return isId(this.entry.patient) ? RelatedPatient.get(this.entry.patient) : this.entry.patient;
    }

    @GetterCache()
    get modifiedAtText() {
        const date = this.entry.modified_at || this.entry.created_at;
        return date ? `Last modification: ${SimpleDatePipe.transform(date, false, true)}` : null;
    }
}
